import { useRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

const DebouncedTextField = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number | null;
    onChange: (value: string | number) => void;
    debounce?: number;
} & TextFieldProps) => {
    const [value, setValue] = React.useState<string | number | null>(initialValue);
    const isInitialMount = useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        if (value === null) return;
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    React.useEffect(() => {
        setValue(initialValue ? initialValue : '');
    }, [initialValue]);

    return (
        <TextField
            {...props}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    );
};

export default DebouncedTextField;
