import { Cloud } from '@mui/icons-material';
import { lazy } from 'react';
import { NavItemType } from 'types/index.types';
import Loadable from 'ui-component/Loadable';

const DrivePage = Loadable(lazy(() => import('views/pages/drive/DrivePage')));

const drive: NavItemType = {
    id: 'drive',
    title: 'components.menu.drive',
    icon: Cloud,
    type: 'item',
    url: '/drive',
    breadcrumbs: false,
    component: <DrivePage />,
    isAdminOnly: true
};

export default drive;
