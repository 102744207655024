// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import lightboxReducer from './slices/lightbox';
import { api } from './api/api';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    lightbox: lightboxReducer,
    [api.reducerPath]: api.reducer
});

export default reducer;
