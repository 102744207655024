import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LightboxProps, LightboxSettings, LightboxData } from 'types/lightbox.types';
import { boolean } from 'yup/lib/locale';

const initialState: LightboxProps = {
    isOpen: false,
    slides: [],
    hideDownload: false,
    hideZoom: false,
    showRotate: false
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const lightbox = createSlice({
    name: 'lightbox',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<LightboxSettings & LightboxData>) => {
            const { slides, hideDownload, hideZoom, showRotate, index } = action.payload;
            state.isOpen = true;
            state.slides = slides;
            state.index = index;
            state.hideDownload = hideDownload;
            state.hideZoom = hideZoom;
            state.showRotate = showRotate;
        },
        remove: (state) => {
            state.isOpen = false;
            state.slides = initialState.slides;
            state.hideDownload = initialState.hideDownload;
            state.hideZoom = initialState.hideZoom;
            state.showRotate = initialState.showRotate;
        }
    }
});

export default lightbox.reducer;

export const { remove, show } = lightbox.actions;
