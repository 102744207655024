// types
import { MenuProps } from 'types/menu.types';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: MenuProps = {
    drawerOpen: false,
    error: null,
    menu: {}
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        // has error
        hasError(state, action) {
            state.error = action.payload;
        },

        // get dashboard menu
        getMenuSuccess(state, action) {
            state.menu = action.payload;
        }
    }
});

export default menu.reducer;

export const { openDrawer } = menu.actions;
