import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import {
    Avatar,
    ButtonBase,
    Chip,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LinkTarget, NavItemType } from 'types/index.types';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface NavItemProps {
    item: NavItemType;
    level: number;
    parentId: string;
}

const NavRootItem = ({ item, level, parentId }: NavItemProps) => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { layout, borderRadius } = useConfig();

    const { drawerOpen } = useSelector((state) => state.menu);
    const isSelected = pathname === item.url;

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon
            sx={{ strokeWidth: 1.5, width: drawerOpen ? 20 : 24 }}
            style={{ color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary }}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary,
                width: isSelected ? 8 : 6,
                height: isSelected ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        if (matchesSM) dispatch(openDrawer(false));
        if (item.onClick) item.onClick();
    };

    if (item.hidden === true) return <></>;

    return (
        <>
            {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                <>
                    <List
                        disablePadding={!drawerOpen}
                        subheader={
                            item.title &&
                            drawerOpen && (
                                <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                    {t(item.title)}
                                    {item.caption && (
                                        <Typography
                                            variant="caption"
                                            sx={{ ...theme.typography.subMenuCaption }}
                                            display="block"
                                            gutterBottom
                                        >
                                            {item.caption}
                                        </Typography>
                                    )}
                                </Typography>
                            )
                        }
                    >
                        <ListItemButton
                            {...listItemProps}
                            disabled={item.disabled}
                            disableRipple={!drawerOpen}
                            sx={{
                                zIndex: 1201,
                                borderRadius: `${borderRadius}px`,
                                mb: 0.5,
                                pl: drawerOpen ? `${level * 24}px` : 1.25
                            }}
                            selected={isSelected}
                            onClick={() => itemHandler(item.id!)}
                        >
                            <ButtonBase aria-label="theme-icon" sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: level === 1 ? 36 : 18,
                                        svg: { fill: 'black' }
                                    }}
                                >
                                    {itemIcon}
                                </ListItemIcon>
                            </ButtonBase>
                            {(drawerOpen || (!drawerOpen && level !== 1)) && item.title && (
                                <ListItemText primary={<Typography variant={isSelected ? 'h5' : 'body1'}>{t(item.title)}</Typography>} />
                            )}
                            {drawerOpen && item.chip && (
                                <Chip
                                    color={item.chip.color}
                                    variant={item.chip.variant}
                                    size={item.chip.size}
                                    label={item.chip.label}
                                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                                />
                            )}
                        </ListItemButton>
                    </List>
                    {/* group divider */}
                    {drawerOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
                </>
            ) : (
                <List>
                    <ListItemButton
                        selected={isSelected}
                        {...listItemProps}
                        sx={(theme) => ({
                            borderRadius: `36px`,
                            border: `1px solid ${theme.palette.primary.light}`,
                            py: 0,
                            px: 2,
                            my: 0.5,
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'inherit',
                            color: 'black !important'
                        })}
                        color="primary"
                        onClick={() => itemHandler(item.id!)}
                    >
                        <ListItemIcon
                            sx={{
                                my: 'auto',
                                minWidth: !item?.icon ? 18 : 24,
                                mr: '6px',
                                '& path': { color: 'black' }
                            }}
                            color="black"
                        >
                            {itemIcon}
                        </ListItemIcon>
                        {item.title !== undefined && (
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={isSelected ? 'h5' : 'body1'}
                                        color="inherit"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontWeight: isSelected ? '600' : 'initial'
                                        }}
                                    >
                                        {t(item.title)}
                                    </Typography>
                                }
                            />
                        )}
                        {item.chip && (
                            <Chip
                                sx={{ ml: 1 }}
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                </List>
            )}
        </>
    );
};
export default NavRootItem;
