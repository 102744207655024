import {
    AudioFile,
    AudioFileOutlined,
    Image,
    ImageOutlined,
    InsertDriveFile,
    InsertDriveFileOutlined,
    Pause,
    PlayArrow,
    VideoFile,
    VideoFileOutlined
} from '@mui/icons-material';
import { Box, BoxProps, IconButton, SvgIconProps } from '@mui/material';
import useLightbox from 'hooks/useLightbox';
import React from 'react';
import { File } from 'types/file.types';
import ReactPlayer from 'react-player';
import { useImmer } from 'use-immer';

// interface Props extends Omit<SvgIconProps, 'id' | 'name' | 'type'>, File {
//     outlined?: boolean;
//     live?: boolean;
// }

// type Props = { outlined?: boolean } & (LiveProps | IconProps);
//
// type LiveProps = {
//     live: true;
// } & JSX.Element &
//     File;
//
// type IconProps = {
//     live: false;
// } & Omit<SvgIconProps, 'id' | 'name' | 'type'> &
//     File;

interface LiveProps extends File, Omit<BoxProps, 'id' | 'name' | 'type' | 'src'> {
    live: true;
}
// interface LiveProps extends File, Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'type' | 'src' | 'id'> {
//     live: true;
// }
interface IconProps extends File, Omit<SvgIconProps, 'id' | 'name' | 'type'> {
    live: false;
    outlined?: boolean;
}

type Props = LiveProps | IconProps;

// type Props = Omit<SvgIconProps, 'id'> & File ;

const FileIcon = ({ type, src, id, name, subtype, ...props }: Props) => {
    const { show } = useLightbox();

    const [playerSettings, setPlayerSettings] = useImmer<{
        src?: string;
        playing: boolean;
    }>({
        src: undefined,
        playing: false
    });

    const renderIcon = () => {
        switch (type) {
            case 'video':
                if (props.live) {
                    return (
                        // @ts-ignore
                        <Box
                            {...props}
                            component={VideoFile}
                            sx={{ cursor: 'pointer', width: '50%', ...props.sx }}
                            onClick={() => src && show(src, { subtype: subtype })}
                        />
                    );
                } else {
                    if (props.outlined) {
                        return (
                            <VideoFileOutlined
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    } else {
                        return (
                            <VideoFile
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    }
                }
            case 'image':
                if (props.live) {
                    return (
                        <Box
                            {...props}
                            component="img"
                            src={src}
                            sx={{ cursor: 'pointer', width: '50%', ...props.sx }}
                            onClick={() => src && show(src, { subtype: subtype })}
                        />
                    );
                } else {
                    if (props.outlined) {
                        return (
                            <ImageOutlined
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    } else {
                        return (
                            <Image
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    }
                }
            case 'audio':
                if (props.live) {
                    return (
                        <Box {...props}>
                            {playerSettings.playing ? (
                                <IconButton
                                    onClick={() =>
                                        setPlayerSettings((draft) => {
                                            draft.playing = false;
                                            draft.src = undefined;
                                        })
                                    }
                                >
                                    <Pause />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() =>
                                        setPlayerSettings((draft) => {
                                            draft.playing = true;
                                            draft.src = src;
                                        })
                                    }
                                >
                                    <PlayArrow />
                                </IconButton>
                            )}
                        </Box>
                    );
                } else {
                    if (props.outlined) {
                        return (
                            <AudioFileOutlined
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    } else {
                        return (
                            <AudioFile
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    }
                }
            case 'application':
                if (props.live) {
                    return (
                        // @ts-ignore
                        <Box
                            {...props}
                            component={InsertDriveFile}
                            sx={{ cursor: 'pointer', width: '50%', ...props.sx }}
                            onClick={() => src && show(src, { subtype: subtype })}
                        />
                    );
                } else {
                    if (props.outlined) {
                        return (
                            <InsertDriveFileOutlined
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    } else {
                        return (
                            <InsertDriveFile
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    }
                }
            case 'text':
                if (props.live) {
                    return (
                        // @ts-ignore
                        <Box
                            {...props}
                            component={InsertDriveFile}
                            sx={{ cursor: 'pointer', width: '50%', ...props.sx }}
                            onClick={() => src && show(src, { subtype: subtype })}
                        />
                    );
                } else {
                    if (props.outlined) {
                        return (
                            <InsertDriveFileOutlined
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    } else {
                        return (
                            <InsertDriveFile
                                {...props}
                                sx={{ ...props.sx, cursor: 'pointer' }}
                                onClick={() => src && show(src, { subtype: subtype })}
                            />
                        );
                    }
                }
            default:
                return <>Error</>;
        }
    };

    return (
        <>
            <ReactPlayer width={0} height={0} url={playerSettings.src} playing={playerSettings.playing} />
            {renderIcon()}
        </>
    );
};

export default FileIcon;
