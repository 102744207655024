import NiceModal from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Close } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
    Grid2 as Grid,
    Stack
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';

import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateClientMutation, useEditClientMutation, useFetchClientQuery, useFetchClientsQuery } from 'store/api/clients.api';
import { useFetchUserDataQuery } from 'store/api/user.api';
import { gridSpacing } from 'store/constant';
import { CompanyClient, CompanyClientSchema, MinimalClient } from 'types/client.types';
import Loader from 'ui-component/Loader';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { clientStepAtom } from 'ui-component/modals/clients/ClientManageModal';
import NaturalPersonAddModal from 'ui-component/modals/clients/NaturalPersonAddModal';
import countryList from 'utils/data/countries';
import { z } from 'zod';

interface Props {
    clientId?: number;
    isRepresentativesEnabled?: boolean;
    isMinimal?: boolean;
    isModal?: boolean;
    redirect?: boolean;
    onClose?: () => void;
    onSubmitAdditions?: (clientId: number) => void;
}

const CompanyForm = ({
    clientId,
    isRepresentativesEnabled = true,
    isMinimal = false,
    redirect = false,
    onClose,
    isModal = false,
    onSubmitAdditions
}: Props) => {
    const { user } = useAuth();
    const { t } = useTranslation();

    const { data: clients } = useFetchClientsQuery(null);
    const { data: client, isLoading: isClientLoading } = useFetchClientQuery({ clientId: clientId! }, { skip: !clientId });
    const { data: userData } = useFetchUserDataQuery({ userId: user?.id! }, { skip: !user });

    const [_step, setStep] = useAtom(clientStepAtom);

    const [createClient] = useCreateClientMutation();
    const [editClient] = useEditClientMutation();

    const gridSizes = isMinimal ? { size: { xs: 12, sm: 4 } } : { size: { xs: 12, sm: 6, md: 3 } };

    const navigate = useNavigate();

    const naturalPersons = clients?.filter((client) => client.person_or_company === 'natural_person');

    const defaultValues: CompanyClient = {
        company_name: '',
        company_number: '',
        person_or_company: 'company',
        additional_phone_number: '',
        company_address: '',
        company_country: 'Lithuania',
        company_email_address: '',
        company_mobile_phone_number: '',
        company_postal_address: '',
        company_postal_code: '',
        company_url: '',
        representatives: [],
        postal_place: ''
    };

    const { handleSubmit, control, setValue, reset } = useForm<CompanyClient>({
        resolver: zodResolver(CompanyClientSchema),
        defaultValues
    });

    useEffect(() => {
        if (!userData) return;
        if (userData.user_origin_country.length === 1) setValue('company_country', userData.user_origin_country[0].name);
    }, [userData]);

    const SubmitCompanyClientSchema = CompanyClientSchema.extend({
        representativesIds: z.array(z.number())
    });

    type SubmitCompanyClient = z.infer<typeof SubmitCompanyClientSchema>;

    const onSubmit: SubmitHandler<CompanyClient> = (data) => {
        // If clientId provided then edit the user
        if (clientId) {
            const requestData = data as SubmitCompanyClient;
            requestData.representativesIds = [];
            requestData.representatives?.forEach((company: MinimalClient) => requestData.representativesIds.push(company.id));
            if (onClose) onClose();
            editClient({ client: requestData, clientId }).then(() => toast.success(t('notifications.success.edited')));
            if (onSubmitAdditions) onSubmitAdditions(clientId);
        } else {
            const requestData = data as SubmitCompanyClient;
            requestData.representativesIds = [];
            requestData.representatives?.forEach((company: MinimalClient) => requestData.representativesIds.push(company.id));
            if (onClose) onClose();
            createClient({ client: requestData }).then((data) => {
                if ('data' in data) {
                    const clientId = data.data.clientId;
                    toast.success(t('notifications.success.created'));
                    if (onSubmitAdditions) onSubmitAdditions(data.data.clientId);
                    if (clientId && redirect) navigate(`/client/manage/${clientId}`);
                }
            });
        }
    };

    useEffect(() => {
        if (!clientId || !client || client.person_or_company === 'natural_person') return;
        reset(client);
    }, [client]);

    if (isClientLoading) return <Loader />;

    return (
        <Stack component={'form'} sx={{ gap: gridSpacing }} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={gridSpacing}>
                <Grid {...gridSizes}>
                    <Controller
                        control={control}
                        name="company_name"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                                label={t('pages.manage_client_page.company_name')}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_number"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.company_number')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_address"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.address')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_country"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <TextField
                                fullWidth
                                value={value}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                }}
                                select
                                variant="standard"
                                label={t('global.fields.country')}
                                error={!!error}
                                helperText={error?.message}
                            >
                                {countryList.map((country) => (
                                    <MenuItem key={country.id} value={country.text}>
                                        {country.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_postal_code"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.postal_code')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        control={control}
                        name="postal_place"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.postal_place')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_mobile_phone_number"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.company_phone_number')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="additional_phone_number"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.additional_phone_number')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_email_address"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('pages.manage_client_page.company_email_address')}
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="company_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth label="URL" error={!!error} helperText={error?.message} variant="standard" />
                        )}
                    />
                </Grid>
                {isRepresentativesEnabled && naturalPersons && (
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Controller
                            name="representatives"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Autocomplete
                                        multiple
                                        value={value}
                                        sx={{ width: '100%' }}
                                        options={naturalPersons}
                                        onChange={(event, value) => {
                                            onChange(value);
                                        }}
                                        getOptionLabel={(option) => option.title}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderTags={(options, getTagProps) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                {options.map((option, index) => (
                                                    <Paper
                                                        sx={{ display: 'flex', gap: 1, alignItems: 'center', py: 0.1, px: 0.5 }}
                                                        elevation={4}
                                                        {...getTagProps({ index: option.id })}
                                                    >
                                                        <Stack>
                                                            <Link
                                                                style={{ textDecoration: 'none', color: 'black' }}
                                                                to={`/client/manage/${option.id}`}
                                                            >
                                                                <Typography variant="caption" fontWeight={600}>
                                                                    {option.title}
                                                                </Typography>
                                                            </Link>
                                                        </Stack>
                                                        <Close
                                                            sx={{ height: 15, width: 15, cursor: 'pointer' }}
                                                            onClick={(event) => getTagProps({ index }).onDelete(event)}
                                                        />
                                                    </Paper>
                                                ))}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Representatives" />}
                                    />
                                    <Box bgcolor={'lightgreen'} sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                                        <Tooltip title={t('pages.manage_client_page.add_new_representative')}>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    if (isModal) {
                                                        setStep('representative');
                                                    } else {
                                                        NiceModal.show(NaturalPersonAddModal);
                                                    }
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <AnimateButton>
                    <Button variant="contained" size="small" type="submit">
                        {t('global.buttons.save')}
                    </Button>
                </AnimateButton>
            </Box>
        </Stack>
    );
};

export default CompanyForm;
