import React from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import styles from '../../assets/scss/reactMentions.module.scss';

interface Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    mentionUsers: SuggestionDataItem[];
    onEnterPress: (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const ChatInputField = ({ mentionUsers, setValue, value, onEnterPress }: Props) => {
    return (
        <MentionsInput
            value={value}
            onChange={(event) => setValue(event.target.value)}
            a11ySuggestionsListLabel={'Suggested mentions'}
            classNames={styles}
            allowSuggestionsAboveCursor
            forceSuggestionsAboveCursor
            onKeyDown={onEnterPress}
        >
            <Mention
                data={mentionUsers}
                trigger="@"
                markup={`@[__id__]`}
                displayTransform={(id, display) => `@${mentionUsers.filter((x) => x.id === Number(id)).map((x) => x.display)}`}
            />
        </MentionsInput>
    );
};

export default ChatInputField;
