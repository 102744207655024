import React from 'react';
import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';

const Drawer = React.forwardRef((props: DrawerProps, ref: any) => {
    return (
        <MuiDrawer
            {...props}
            sx={{ ...props.sx }}
            PaperProps={{
                ...props.PaperProps,
                sx: {
                    maxWidth: '90%',
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                    ...props.PaperProps?.sx
                }
            }}
            ref={ref}
        >
            {props.children}
        </MuiDrawer>
    );
});

export default Drawer;
