import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { atom, useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useFetchClientQuery, useFetchClientsQuery } from 'store/api/clients.api';
import { NaturalPersonClient } from 'types/client.types';
import { BackModalState } from 'types/global.types';
import ModalFormCard from 'ui-component/cards/ModalCard';
import SubCard from 'ui-component/cards/SubCard';
import CompanyForm from 'views/forms/clients/CompanyForm';
import NaturalPersonForm from 'views/forms/clients/NaturalPersonForm';

interface Props {
    clientId?: number;
}

export type BackButtonExtended = BackModalState | 'representative' | 'companies';

export const clientStepAtom = atom<BackButtonExtended>(0);

export const createdClientIdAtom = atom<null | number>(null);

const ClientManageModal = NiceModal.create(({ clientId }: Props) => {
    const modal = useModal();

    const { data: clients, isLoading: isClientsLoading, isFetching: isClientsFetching } = useFetchClientsQuery(null, {});
    const { data: clientData, isLoading: isClientLoading } = useFetchClientQuery({ clientId: clientId! }, { skip: !clientId });
    const client = clientData as NaturalPersonClient;

    const [clientTabIndex, setClientTabIndex] = useState(0);
    const [step, setStep] = useAtom(clientStepAtom);
    const [createdClientId, setCreatedClientId] = useAtom(createdClientIdAtom);

    useEffect(() => {
        if (!clientId || !client) return;
        setClientTabIndex(client?.person_or_company === 'company' ? 1 : 0);
    }, [client]);

    const onClose = () => {
        setStep(0);
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={onClose}>
            <>
                <ModalFormCard
                    title={clientId ? 'Edit Client' : 'Create Client'}
                    onClose={onClose}
                    sx={{ display: step === 0 ? 'block' : 'none' }}
                >
                    <SubCard
                        title={clientTabIndex === 0 ? 'Person' : 'Business'}
                        sx={{ width: '100%' }}
                        secondary={
                            <>
                                {!clientId && !isClientsLoading && !isClientsFetching && (
                                    <ToggleButtonGroup
                                        value={clientTabIndex}
                                        exclusive
                                        onChange={(event, value) => setClientTabIndex(value)}
                                    >
                                        <ToggleButton size="small" value={0}>
                                            Person
                                        </ToggleButton>
                                        <ToggleButton size="small" value={1}>
                                            Business
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </>
                        }
                    >
                        {clientTabIndex === 0 ? (
                            <NaturalPersonForm
                                isMinimal={false}
                                isCompaniesEnabled={true}
                                onClose={onClose}
                                clientId={clientId}
                                isModal
                                onSubmitAdditions={(clientId) => setCreatedClientId(clientId)}
                            />
                        ) : (
                            <CompanyForm
                                isMinimal={false}
                                isRepresentativesEnabled={true}
                                onClose={onClose}
                                clientId={clientId}
                                isModal
                                onSubmitAdditions={(clientId) => setCreatedClientId(clientId)}
                            />
                        )}
                    </SubCard>
                </ModalFormCard>
                <ModalFormCard
                    onClose={onClose}
                    onBack={() => {
                        setStep(0);
                    }}
                    sx={{ display: step === 'companies' ? 'block' : 'none' }}
                    title="Add Company"
                >
                    <CompanyForm
                        isRepresentativesEnabled={false}
                        isModal={true}
                        onClose={() => {
                            setStep(0);
                        }}
                    />
                </ModalFormCard>
                <ModalFormCard
                    onClose={onClose}
                    onBack={() => {
                        setStep(0);
                    }}
                    sx={{ display: step === 'representative' ? 'block' : 'none' }}
                    title="Add Representative"
                >
                    <NaturalPersonForm
                        isCompaniesEnabled={false}
                        isModal={true}
                        onClose={() => {
                            setStep(0);
                        }}
                    />
                </ModalFormCard>
            </>
        </Modal>
    );
});
export default ClientManageModal;
