import React, { useRef } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

const DebouncedWysiwyg = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string;
    onChange: (value: string) => void;
    debounce?: number;
} & IAllProps) => {
    const [value, setValue] = React.useState<string>(initialValue);
    const isInitialMount = useRef(true);

    React.useEffect(() => {
        if (value === initialValue) return;
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        if (value === null) return;
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <Editor
            {...props}
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            value={value}
            init={{
                height: '100%',
                min_height: 300,
                menubar: false,
                plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'code',
                    'help',
                    'wordcount'
                ],
                toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            onEditorChange={(newValue, editor) => {
                setValue(newValue);
            }}
        />
    );
};

export default DebouncedWysiwyg;
