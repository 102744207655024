import React from 'react';
import useActions from './useActions';
import { LightboxProps, LightboxSettings } from 'types/lightbox.types';
import { Subtype } from 'types/file.types';
import { Slide } from 'yet-another-react-lightbox';

const useLightbox = () => {
    const { remove, show } = useActions();

    const handleShow = (
        url: string | string[],
        { hideDownload, hideZoom, showRotate, subtype: providedSubtype, index }: LightboxSettings = {
            hideDownload: false,
            hideZoom: false,
            showRotate: true
        }
    ) => {
        const slides: Slide[] = [];
        if (typeof url === 'string') {
            const subtype = providedSubtype || (url.split(/[#?]/)[0].split('.').pop()!.trim() as Subtype).toLowerCase();
            const name = url.substring(url.lastIndexOf('/') + 1);

            slides.push({ src: url, subtype, download: { filename: name, url } });
        } else
            for (const image of url) {
                const subtype = providedSubtype || (image.split(/[#?]/)[0].split('.').pop()!.trim() as Subtype).toLowerCase();
                const name = image.substring(url.lastIndexOf('/') + 1);
                slides.push({ src: image, subtype, download: { filename: name, url: image } });
            }
        show({ slides, hideDownload, hideZoom, showRotate, index });
    };

    const handleRemove = () => {
        remove();
    };

    return { show: handleShow, remove: handleRemove };
};

export default useLightbox;
