import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import getMenuItems from 'menu-items';
import NavGroup from './NavGroup';
import { NavItemType } from 'types/index.types';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import NavRootItem from './NavRootItem';
import useAuth from 'hooks/useAuth';
import { roleCheck } from 'helpers/users.helper';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));

    useEffect(() => {
        console.debug({ matchDownMd, matchUpSm, matchUpMd, matchUpLg, matchUpXl });
    }, [matchDownMd, matchUpSm, matchUpMd, matchUpLg, matchUpXl]);

    const { layout } = useConfig();
    const { user } = useAuth();
    const { t } = useTranslation();

    const [menuItem, setMenuItem] = useState<{ items: NavItemType[] }>({ items: [] });

    useEffect(() => {
        getMenuItems().then((result) => {
            setMenuItem(result);
        });
    }, []);

    const [horizontalMaxItem, setHorizontalMaxItem] = useState(6);

    useEffect(() => {
        if (matchUpXl) {
            setHorizontalMaxItem(10);
        } else if (matchUpLg) {
            setHorizontalMaxItem(9);
        } else if (matchUpMd) {
            setHorizontalMaxItem(7);
        } else if (matchUpSm) {
            setHorizontalMaxItem(6);
        } else {
            setHorizontalMaxItem(5);
        }
    }, []);

    if (!user) return <>loading</>;

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? horizontalMaxItem : null;

    let lastItemIndex = menuItem.items.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id!;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => {
            switch (item.type) {
                case 'item':
                    return { url: item.url, title: item.title, type: item.type, icon: item.icon };
                default:
                    return {
                        title: item.title,
                        elements: item.children,
                        type: item.type
                    };
            }
        });
    }

    // Returns
    const navItems = menuItem.items
        .slice(0, lastItemIndex + 1)
        .filter((item) => {
            let pass = true;
            if (!roleCheck({ item, role: user?.role! })) pass = false;
            else if (item.hidden) pass = false;
            else if (item.children && item.children.length === 0) pass = false;

            return pass;
        })
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
                case 'item':
                    return <NavRootItem key={item.id} item={item} level={1} parentId={item.id!} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {t('components.menu.menu_items_error')}
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default memo(MenuList);
